import Vue from 'vue/dist/vue.esm.js';
document.addEventListener('DOMContentLoaded', () => {
  /**============================================================
  *   [form] select
  *   description : selectのvalueが空の時にテキスト色を変更する処理を記述
  ============================================================*/
  const selects = document.getElementsByTagName('select');
  selects.forEach(select => {

    if (select.value !== '') {
      select.classList.remove('is-value-empty');
    }else{
      select.classList.add('is-value-empty');
    }

    select.addEventListener('change', function() {
      if (this.value !== '') {
        select.classList.remove('is-value-empty');
      }else{
        select.classList.add('is-value-empty');
      }
    })

  })
  /**============================================================
  *   [form] toggle-pass-view, login-button
  *   description : パスワードの表示/非表示切り替えの処理を記述
  *                 ログインパネルがある場合は＋ボタンを活性・非活性にする
  ============================================================*/
  if(document.getElementById('login')) {
    new Vue({
      el: '#login',
      data: {
        field1: '',
        field2: '',
        isVisible: false,
      },
      computed: {
        entered_all () {
          const required_fields = [
            this.field1,
            this.field2,
          ]
          return required_fields.indexOf('') === -1
        },
        inputType: function () {
          return this.isVisible ? "text" : "password";
        }
      },
      methods: {
        onClick: function() {
          this.isVisible = !this.isVisible;
        }
      }
    })
  }else if(document.querySelectorAll('.c-form-toggle-pass-view')){
    const items = document.querySelectorAll('.c-form-toggle-pass-view');
    items.forEach((el) => {
      new Vue({
        el: el,
        data: {
          isVisible: false,
        },
        computed: {
          inputType: function () {
            return this.isVisible ? "text" : "password";
          }
        },
        methods: {
          onClick: function() {
            this.isVisible = !this.isVisible;
          }
        }
      })
    });
  }
});
