import Swiper, { Pagination } from 'swiper';
Swiper.use([Pagination]);
/**============================================================
*   [新着動画]
*   description : 新着動画スライダーの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  if(document.getElementById('js-rental-new-slider')){
    const swiperSlide = document.getElementById('js-rental-new-slider');
    const swiperSlides = document.getElementsByClassName('swiper-slide');
    let mySwiper;
    let mySwiperBool = false;

    const mql = window.matchMedia('(min-width: 768px)');
    function mqCheck(mq) {
      if (mq.matches) {
        if(mySwiperBool){
          mySwiper.destroy(false,true);
          mySwiperBool = false;
        }
        swiperSlide.classList.add('swiper-disabled');
      }else {
        createSwiper();
        mySwiperBool = true;
        swiperSlide.classList.remove('swiper-disabled');
      }
    }
    mqCheck(mql);
    mql.addEventListener('change', mqCheck, false);

    function createSwiper(){
      mySwiper = new Swiper('#js-rental-new-slider', {
        spaceBetween: 20,
        slidesPerView: 1,
        loop: true,
        loopedSlides: swiperSlides.length,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          568: {
            slidesPerView: 1.5,
          },
          667: {
            slidesPerView: 1.9,
          }
        }
      });
    }
  }
});
