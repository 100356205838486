/**============================================================
*   [footer-page-top]
*   description : ページトップボタン表示非表示・位置設定の処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  if(document.getElementById('js-footer-page-top')){
    const btn = document.getElementById('js-footer-page-top');
    const footer = document.getElementById('l-footer');

    //表示非表示
    window.addEventListener('scroll', function(){
      if( 300 <= window.scrollY ) {
        btn.classList.add('is-show');

      } else {
        btn.classList.remove('is-show');
      }
    });

    //fixed or absolute
    const handleObserve = (elems) => {
      elems.forEach((elem) => {
        if (elem.isIntersecting) {
          btn.classList.remove('is-fixed');
        }else{
          btn.classList.add('is-fixed');
        }
      });
    }
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    };
    const observer = new IntersectionObserver(handleObserve, observerOptions);
    observer.observe(footer);

    //scroll
    btn.addEventListener('click', () =>{
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  }
});
