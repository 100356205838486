import Vue from 'vue/dist/vue.esm.js';
/**============================================================
*   [gnav]
*   description : グローバルナビの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  if(document.getElementById('l-gnav')){
    const gnav = new Vue({
      el: '#vue-gnav',
      data () {
        return {
          isGnavOpened: false,
          categoryNum: 0,
          isSP: (function(){
            if (window.matchMedia('(min-width: 1100px)').matches) {
              return false;
            }else{
              return true;
            }
          })(),
        }
      },
      mounted: function(){
        //初期化
        if (!this.isSP) {
          this.isGnavOpened = true;
        }
        window.addEventListener('resize', () => {
          gnav.setIsSP();
          if(gnav.isSP != gnav.currentSpMode){
            gnav.resetGnav();
          }
        });

        //キャッシュによる表示のリセット対策
        window.onbeforeunload = function() {};
        window.onunload = function() {};
        window.addEventListener('pageshow', (event)=>{
          if (event.persisted) {
            if (gnav.isSP) {
              gnav.resetGnav();
            }
          }
        });

        //キャッシュによる表示のリセット対策（vue外の要素）
        const contentWrapElem = document.getElementById('l-content-wrap');
        const observeTarget = document.getElementById('l-gnav');
        const observer = new MutationObserver(() => {
          // 変化が発生したときの処理を記述
          if(observeTarget.classList.contains('no-animation')){
            contentWrapElem.classList.add('no-animation');
            contentWrapElem.classList.remove('is-gnav-opened');
            document.getElementsByTagName('html')[0].classList.remove('is-gnav-opened');
            document.getElementsByTagName('body')[0].classList.remove('is-gnav-opened');
          }else{
            contentWrapElem.classList.remove('no-animation');
          }
        })
        // クラス監視
        observer.observe(observeTarget, {
          attributes: true,
          attributeFilter: ['class']
        })
      },
      methods: {
        isCategorySelect: function(num,event) {
          let currentNum = this.categoryNum;
          this.setIsSP();

          if(event.type == 'mouseenter'){
            if (!this.isSP) {
              if(currentNum == num){
                this.categoryNum = 0;
                this.isGnavOpened = false;
              }else{
                this.categoryNum = num;
                this.isGnavOpened = true;
              }
            }
          }else{
            if (this.isSP) {
              if(currentNum == num){
                this.categoryNum = 0;
                this.isGnavOpened = false;

              }else{
                this.categoryNum = num;
                this.isGnavOpened = true;

              }
            }
          }

        },
        isToggleGnav: function(){
          if(this.isGnavOpened){
            this.isGnavOpened = false;
            document.getElementById('l-content-wrap').classList.remove('is-gnav-opened');
            document.getElementsByTagName('html')[0].classList.remove('is-gnav-opened');
            document.getElementsByTagName('body')[0].classList.remove('is-gnav-opened');
          }else{
            this.isGnavOpened = true;
            document.getElementById('l-content-wrap').classList.add('is-gnav-opened');
            document.getElementsByTagName('html')[0].classList.add('is-gnav-opened');
            document.getElementsByTagName('body')[0].classList.add('is-gnav-opened');
          }
        },
        isGnavClose: function(event){
          this.setIsSP();
          if(event){
            if (event.type == 'click' || event.type == 'mouseleave') {
              this.categoryNum = 0;
              this.isGnavOpened = false;
            }
          }else if(this.isSP) {
            this.categoryNum = 0;
            this.isGnavOpened = false;
          }
        },
        resetGnav: function(){
          this.setIsSP();

          //ブラウザバック時のみ、アニメーションを切る
          document.getElementById('l-gnav').classList.add('no-animation');
          document.getElementById('vue-gnav').classList.add('no-animation');

          if(this.isSP){
            //SP時のデフォルト状態
            this.isGnavClose();
          }else{
            //PC時のデフォルト状態
            this.isGnavOpened = true;
            document.documentElement.classList.remove('ov-hidden');
          }
          this.currentSpMode = this.isSP;
        },
        setIsSP: function(){
          if (window.matchMedia('(min-width: 1100px)').matches) {
            this.isSP = false;
          }else{
            this.isSP = true;
          }
        },
        transitionLeave: function(){
          document.getElementById('l-gnav').classList.remove('no-animation');
          document.getElementById('vue-gnav').classList.remove('no-animation');
        }
      }
    })
  }
})
