/**============================================================
*   [anchor]
*   description : ページ内リンクの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  const anchorLinks = document.querySelectorAll('a[href^="#"]')
  const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
  let header = document.getElementById('vue-gnav');
  let headerHeight = header.offsetHeight;

  const mqlAnchor = window.matchMedia('(min-width: 768px)');
  function mqlAnchorCheck() {
    header = document.getElementById('vue-gnav');
    headerHeight = header.offsetHeight;
  }
  mqlAnchorCheck(mqlAnchor);
  mqlAnchor.addEventListener('change', mqlAnchorCheck, false);

  anchorLinksArr.forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();
      const targetId = link.hash;
      const targetElement = document.querySelector(targetId);
      const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
      const totalScrollAmount = targetOffsetTop - headerHeight;
      window.scrollTo({
        top: totalScrollAmount,
        behavior: 'smooth'
      });
    });
  });
});
